<template>
	<body style="position: relative;">

		<div id="wrap">
	
			<section>
				<headerLayout ref="headerComponent"></headerLayout>
			</section>

			<main>
	
				<section class="sub-visual">
					<div class="innWrap">
						<div class="txt">
							<h2>가입</h2>
							<p>※ 기타 상품 가입은 담당 영업 및 고객센터(1588-0114)를 통해 문의하시기 바랍니다.</p>
						</div>
						<div class="location">
							<ul>
								<li><a href="#">ORDER</a></li>
								<li><a href="#">서비스 신청</a></li>
								<li><span>가입</span></li>
							</ul>
						</div>
					</div>
				</section>
	
				<section class="sub-sectionpb svcjoin">
					<div class="scrWrap-02">

						<!-- TAB START -->
						<div class="scrNav">
							<ul class="innWrap">
								<li @click="updateTab(1)"><a v-bind:class="{active: active === 1}" >01. 기본 정보</a></li>
								<li @click="updateTab(2)"><a v-bind:class="{active: active === 2}" v-smooth-scroll>02. 납부정보</a></li>
								<li @click="updateTab(3)"><a v-bind:class="{active: active === 3}" v-smooth-scroll>03. 기본서비스정보</a></li>
								<li @click="updateTab(4)"><a v-bind:class="{active: active === 4}" v-smooth-scroll>04. 부가서비스 정보</a></li>
								<li @click="updateTab(5)"><a v-bind:class="{active: active === 5}" v-smooth-scroll>05. 예상금액</a></li>
								<li @click="updateTab(6)"><a v-bind:class="{active: active === 6}" v-smooth-scroll>06. 판매자 정보</a></li>
								<li @click="updateTab(7)"><a v-bind:class="{active: active === 7}" v-smooth-scroll>07. 동의</a></li>
							</ul>
						</div>
						<!-- TAB END -->
						
						<div class="scrCon innWrap">
							<div id="Accordion_wrap">

								<!-- 01. 기본 정보 START -->
								<div id="section-011" class="join-section-01">
									<div class="que">
										<h3 class="sLtitle">기본 정보<span class="required">*필수 입력</span></h3>
									</div>
									<div class="anw basicInfo">
										<div class="top-search a-left">
											<a class="btn-md02 btn-primary btn-search pop-open" @click="openPopup('userInfo')">내 정보 조회</a>
											<span class="asterisk" style="margin-left: 20px;">가입 되어 있는 기본 정보 조회가 가능합니다.</span>
										</div>
										<div class="lineBox con01 form-list ">
											<div class="form-Item item01">
												<label for="con01-01">구분<span class="required">*</span></label>
												<div class="fcon">
													<div class="radio three multi">
														<input type="radio" name="custNoType" id="division_1" value="3" v-model="custNoType" disabled/>
														<label for="division_1">개인사업</label>
														<input type="radio" name="custNoType" id="division_2" value="8" v-model="custNoType" disabled/>
														<label for="division_2">법인사업</label>
														<input type="radio" name="custNoType" id="division_3" value="9" v-model="custNoType" disabled/>
														<label for="division_3">비영리/공공기관</label>
													</div>
												</div>
											</div>
											<div class="form-Item item02 label_2ea">
												<label for="con01-02-01">법인명(고객명)<span class="required">*</span></label>
												<div class="fcon1">
													<input type="text" style="color: #111;" id="con01-02-01" placeholder="고객정보를 선택하세요." v-model="userInfoSel.corpNm" readonly>
												</div>
												<label for="con01-02-02" class="lable_2 w270px">법인등록번호(생년월일)<span class="required">*</span></label>
												<div class="fcon2">
													<input type="text" style="color: #111;" id="con01-02-02" placeholder="고객정보를 선택하세요." v-model="userInfoSel.corpNo" readonly>
												</div>
											</div>
											<div class="form-Item item03 label_2ea">
												<label for="con01-03-01">대표자명<span class="required">*</span></label>
												<div class="fcon1">
													<input type="text" style="color: #111;" id="con01-03-01" placeholder="고객정보를 선택하세요." class="w465px" v-model="userInfoSel.reprNm" readonly>
												</div>
												<label for="con01-03-02" class="lable_2 w270px">사업자등록번호<span class="required">*</span></label>
												<div class="fcon2">
													<input type="text" style="color: #111;" id="con01-03-02" placeholder="고객정보를 선택하세요." class="w465px" v-model="userInfoSel.custIdfyNo" readonly>
												</div>
											</div>
											<div class="form-Item item06">
												<label for="con01-06">신청자<span class="required">*</span></label>
												<div class="fcon multi">
													<input type="text" placeholder="이름" v-model="comNm" ref="comNm">
													<input type="tel" placeholder="연락처" v-model="comTel" ref="comTel">
													<input type="email" placeholder="이메일" v-model="comEmail" ref="comEmail">
												</div>
											</div>
										</div>
										<div class="lineBox con02 form-list mgt60">
											<div class="form-Item item01">
												<label for="con02-04">신청구분<span class="required">*</span></label>
												<div class="fcon">
													<div class="radio two multi">
														<input type="radio" name="division3" id="division3_1" value="01" v-model="appDiv" checked/>
														<label for="division3_1">본인신청 (대표자 신청)</label>
														<input type="radio" name="division3" id="division3_2" value="02" v-model="appDiv"/>
														<label for="division3_2">대리신청</label>
													</div>
												</div>
											</div>
											<div class="form-Item item02">
												<label for="con02-05">첨부서류(본인신청)<span class="required">*</span></label>
												<div class="fcon">
													<div class="multi">
														<span>사업자등록증</span>
														<input type="text" id="imgBizTxt" placeholder="사업자등록증을 등록하세요" disabled style="width:100px; !important">
														<input type="file" id="imgBiz" name="imgBiz" accept="image/*" style="display:none;" data-key="imgBiz" ref="imgBiz" @change="uploadFile">
														<label for="imgBiz" class="btn-md01 btn-primary">찾아보기…</label>
													</div>
													<div class="multi mgt20" v-show="appDiv=='02'">
														<span>대리인신분증</span>
														<input type="text" id="imgAgeTxt" placeholder="대리인신분증을 등록하세요" disabled>
														<input type="file" id="imgAge" name="imgAge" accept="image/*" style="display:none;" data-key="imgAge" ref="imgAge" @change="uploadFile">
														<label for="imgAge"  class="btn-md01 btn-primary">찾아보기…</label>
													</div>
													<div class="multi mgt20" v-show="appDiv=='01'">
														<span>대표자신분증</span>
														<input type="text" id="imgCeoTxt" placeholder="대표자신분증을 등록하세요" disabled>
														<input type="file" id="imgCeo" name="imgCeo" accept="image/*" style="display:none;" data-key="imgCeo" ref="imgCeo" @change="uploadFile">
														<label for="imgCeo"  class="btn-md01 btn-primary">찾아보기…</label>
													</div>
													<div class="multi mgt20" v-show="appDiv=='02'">
														<span>인감증명서</span>
														<input type="text" id="imgSealTxt" placeholder="인감증명서을 등록하세요" disabled>
														<input type="file" id="imgSeal" name="imgSeal" accept="image/*" style="display:none;" data-key="imgSeal" ref="imgSeal" @change="uploadFile">
														<label for="imgSeal"  class="btn-md01 btn-primary">찾아보기…</label>
													</div>
													<div class="multi mgt20" v-show="appDiv=='02'">
														<span>위임장</span>
														<input type="text" id="imgWarTxt" placeholder="위임장을 등록하세요" class="w480px" disabled>
														<input type="file" id="imgWar" name="imgWar" accept="image/*" style="display:none;" data-key="imgWar" ref="imgWar" @change="uploadFile">
														<a @click="download" class="btn-md01 btn-line02 btn-down">위임장 샘플 다운로드</a>
														<label for="imgWar" class="btn-md01 btn-primary">찾아보기…</label>
													</div>
												</div>
											</div>
											<p class="asterisk_red pdl320 mgb20">※ 파일 용량은 30MB 이하여야 합니다.</p>
											<br/>
											<p class="asterisk_red pdl320 mgb20" v-show="appDiv=='02'">※ 인감증명서, 위임장은 발급일부터 3개월 이내인 경우만 신청이 유효합니다.</p>
											<div class="form-Item item03">
												<label for="con02-06">설치주소<span class="required">*</span></label>
												<div class="fcon multi">
													<input type="text" id="con02-06" placeholder="건물명 / 층 / 호수 까지 정확히 기입해주세요" v-model="fullAddr" readonly>
													<a @click="openPopup('locationInfo')" class="btn-md01 btn-primary">검색</a>
												</div>
											</div>
											<div class="asterisk_bk pdl320">※ 서비스 제공 불가 지역의 경우 신청이 제한될 수 있습니다</div>
										</div>
									</div>
								</div>
								<!-- 01. 기본 정보 END -->

								<!-- 02. 납부정보 START -->
								<div id="section-022" class="join-section-02">
									<div class="que">
										<h3 class="sLtitle">납부 정보<span class="required">*필수 입력</span></h3>
									</div>
									<div class="anw payInfo">
										<div class="top-search a-left">
											<a @click="openPopup('accountInfo')" class="btn-md02 btn-primary btn-search pop-open">내 납부정보 조회</a>
											<span class="asterisk" style="margin-left: 20px;">등록되어 있는 납부 정보 조회가 가능합니다.</span>
										</div>
										<div class="lineBox form-list">
											<div class="form-Item item01 label_2ea">
												<label for="paycon-01-01">납부자정보<span class="required">*</span></label>
												<div class="fcon multi">
													<input type="text" id="paycon-01-01" placeholder="법인(고객)명" v-model="this.userInfoSel.corpNm" readonly>
													<input type="text" id="paycon-01-02" placeholder="법인등록번호(생년월일)" v-model="this.userInfoSel.corpNo" readonly>
													<input type="text" id="paycon-01-03" placeholder="사업자등록번호" v-model="this.userInfoSel.custIdfyNo" readonly>
												</div>
											</div>
											<div class="form-Item item02">
												<label for="paycon-02">납부방법<span class="required">*</span></label>
												<div class="fcon">
													<div class="radio h52 three multi">
														<input type="radio" name="pay" id="pay_1" value="지로" v-model="payMethCd" disabled/>
														<label for="pay_1">지로 (우편 or 이메일)</label>
														<input type="radio" name="pay" id="pay_2" value="은행계좌 자동이체" v-model="payMethCd" disabled/>
														<label for="pay_2">자동이체</label>
														<input type="radio" name="pay" id="pay_3" value="신용/체크카드" v-model="payMethCd" disabled/>
														<label for="pay_3">카드이체</label>
													</div>
												</div>
											</div>
											<div class="form-Item item03">
												<label for="paycon-03">납부정보<span class="required">*</span></label>
												<div class="fcon multi">
													<input type="text" placeholder="은행(카드사)명" v-model="this.accountInfoSel.payOrgNm" readonly>
													<input type="text" placeholder="계좌(카드)번호" v-model="this.accountInfoSel.payNo" readonly>
													<input type="text" placeholder="예금(카드)주명" v-model="this.accountInfoSel.chagePayPrsnNm" readonly>
													<input type="text" placeholder="카드 유효 기간  MM / YY" readonly>
												</div>
											</div>
											<div class="form-Item item04">
												<label for="paycon-04">명세서종류<span class="required">*</span></label>
												<div class="fcon multi">
													<div class="radio h52 multi">
														<input type="radio" name="paytype" id="paytype_1" value="이메일" v-model="acrndDspTypeCd" disabled/>
														<label for="paytype_1">이메일</label>
														<input type="radio" name="paytype" id="paytype_2" value="우편" v-model="acrndDspTypeCd" disabled/>
														<label for="paytype_2">우편</label>
													</div>
												</div>
											</div>
											<div class="form-Item item05">
												<label for="paycon-05">이메일주소<span class="required">*</span></label>
												<div class="fcon">
													<input type="email" id="paycon-05" placeholder="명세서를 받으실 이메일 주소를 입력하세요" v-model="this.accountInfoSel.emailAdr" readonly>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- 02. 납부정보 END -->

								<!-- 03. 기본서비스정보 START -->
								<div id="section-033" class="join-section-03">
									<div class="que">
										<h3 class="sLtitle">기본 서비스 정보<span class="required">*필수 입력</span></h3>
									</div>
									<div class="anw basicSvcInfo">
										<div class="lineBox form-list">
											<div class="form-Item item01">
												<label for="basicSvccon-01">가입유형<span class="required">*</span></label>
												<div class="fcon multi">
													<div class="radio h52 two multi">
														<input type="radio" name="svcgender" id="svcgender_1" value="" checked />
														<label for="svcgender_1">신규</label>
														<input type="radio" name="svcgender" id="svcgender_2" value="" disabled/>
														<label for="svcgender_2">전환</label>
													</div>
												</div>
											</div>
											<div class="form-Item item02">
												<label for="basicSvccon-02">서비스명<span class="required">*</span></label>
												<div class="fcon multi">
													<div class="select w584px" id="basicSvccon-02-01">
														<!-- span class="placeholder" v-if="fullAddr == ''"><span class="fcGy">설치주소를 입력해주세요</span></span>
														<span class="placeholder" v-else><span class="fcGy">서비스를 선택하세요</span></span>
														<div class="list" v-if="fullAddr != ''"-->
														<span class="placeholder"><span class="fcGy">서비스를 선택하세요</span></span>
														<div class="list">
															<ul>
																<li value="FL" v-on:click="productInfoFilter('FL')">Flexline</li>
																<li value="GO" v-on:click="productInfoFilter('GO')">GiGA office</li>
															</ul>
														</div>
													</div>
													<div class="select w584px" id="basicSvccon-02-02">
														<span class="placeholder" ref="detSvc"><span class="fcGy">세부 서비스를 선택하세요</span></span>
														<div class="list">
															<ul>
																<li v-for="(item ,index) in productInfo" 
																	:key="item.svc_type_cd" 
																	:value="item.svc_type_cd" 
																	:data-name="item.svc_type_nm" 
																	:data-index="index" 
																	@click="selectValue($event, 'DS')"
																	>{{item.svc_type_nm}}</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											<!-- 서비스가 Flexline-Basic 일경우 -->
											<div class="form-Item item02-02" v-show="svcLocSearch">
												<label for="flex-basic">서비스 가능지역 찾기<span class="required">*</span></label>
												<a href="https://help.kt.com/serviceinfo/SearchHomePhone.do" class="btn-md02 btn-primary btn-search02" target="_blank">기가인터넷 가능 지역찾기</a>
												<div class="checkbox form-ckRadio">
													<input type="checkbox" id="basic-checkbox" class="hide" name="chk" v-model="checkLocation">
													<label for="basic-checkbox">확인</label>
												</div>
												<p class="asterisk_red">※ 전산상 설치가능으로 조회되나 실제 도로/건물 사정으로 서비스 신청이 제한 될 수 있습니다.</p>
											</div>
											<!-- 서비스가 Flexline-Premium일경우 -->
											<div class="form-Item item02-02" v-show="(svcNm == 'GO' || (svcNm == 'FL' && svcTypeCd == '20'))">
												<label for="flex-premium">수용지역 선택<span class="required">*</span></label>
												<!--
												<a class="btn-md02 btn-primary btn-search02 pop-open" @click="openPopup('centerInfo')">수용지역 선택</a>
												-->
												<input type="text" id="flex-premium" placeholder="수용지역" v-model="centerInfo" readonly style="width: 588px; margin-left: 0px;">
												<p class="asterisk_red">※ 설치 주소와 가장 인접한 지역이 선택됩니다.</p>
											</div>
											<div class="form-Item item03">
												<label for="basicSvccon-03">속도<span class="required">*</span></label>
												<div class="fcon1 multi">
													<div class="select" id="basicSvccon-03-01">
														<span class="placeholder" ref="downSpdSpan"><span class="fcGy">다운로드 속도</span></span>
														<div class="list">
															<ul>
																<li v-for="item in lineSpeedInfo" 
																	:key="item.displayseq" 
																	:value="item.code_cd" 
																	:data-name="item.code_nm" 
																	@click="selectValue($event, 'LS')">{{svcTypeCd=='11'?`최대 `+item.code_nm : item.code_nm}}</li>
															</ul>
														</div>
													</div>
													<div :class="svcTypeCd != '20' ? 'select disabled' : 'select'" id="basicSvccon-03-01" style="margin-left:32px;">
														<span class="placeholder" ref="uploadSpdSpan"><span class="fcGy">업로드 속도</span></span>
														<div class="list">
															<ul>
																<li v-for="item in lineUploadSpeedInfo" 
																	:key="item.code_cd" 
																	:value="item.code_cd" 
																	:data-name="item.code_nm" 
																	:data-index="item.displayseq" 
																	@click="selectValue($event, 'LSU')" 
																	ref="uploadSpdLi">
																	{{svcTypeCd=='11'?`최대 ` + item.code_nm : (svcTypeCd=='20' ? (item.code_cd == '80' ? item.code_nm+' (기본)': item.code_nm) : item.code_nm)}}
																</li>
															</ul>
														</div>
													</div>
												</div>
												<label for="basicSvccon-04" class="lable_2 form-labelh52" style="margin-left: 20px; margin-right: 20px;">기본 제공 IP</label>
												<input type="text" style="width:107px; text-align:center; important!" readonly :value="`${basicIpCnt}   `">
												<label for="basicSvccon-04" class="lable_2 form-labelh52">추가 IP</label>
												<div class="fcon2 multi">
													<div class="input_num">
														<span class="btn-minus" @click="getAddIpCnt('-')"></span>
														<input type="text" placeholder="" v-model="addIpCnt" readonly style="background:white;color:black; !important">
														<span class="btn-plus" @click="getAddIpCnt('+')"></span>
													</div>
												</div>
											</div>
											<p class="asterisk_red pdl320 mgb20">※ Flexline Basic / Premium+ 는 업다운 대칭, GiGAoffice / Flexline Premium 은 업로드 기본 200M 제공합니다.</p>
											<div class="form-Item item05">
												<label for="basicSvccon-05">계약기간(할인율)<span class="required">*</span></label>
												<div class="fcon multi">
													<div class="radio h52 five multi">
														<input type="radio" name="term" id="term_1" v-model="termCntrctTypeCd" value="EQ0Y" data-name="무약정" @click="selectValue($event, 'TE')"/>
														<label for="term_1">무약정</label>
														<input type="radio" name="term" id="term_2" v-model="termCntrctTypeCd" value="EQ2Y" data-name="2년" @click="selectValue($event, 'TE')"/>
														<label for="term_2">2년 (10%)</label>
														<input type="radio" name="term" id="term_3" v-model="termCntrctTypeCd" value="EQ3Y" data-name="3년" @click="selectValue($event, 'TE')"/>
														<label for="term_3">3년 (15%)</label>
														<input type="radio" name="term" id="term_4" v-model="termCntrctTypeCd" value="EQ4Y" data-name="4년" @click="selectValue($event, 'TE')"/>
														<label for="term_4">4년 (20%)</label>
														<input type="radio" name="term" id="term_5" v-model="termCntrctTypeCd" value="EQ5Y" data-name="5년" @click="selectValue($event, 'TE')"/>
														<label for="term_5">5년 (25%)</label>
													</div>
												</div>
											</div>
											<p class="asterisk_red pdl320 mgb20">※ 무약정으로 가입 후 해지 할 경우 할인 반환금이 발생하지 않습니다.</p>
											<div class="form-Item item06 label_2ea">
												<label for="basicSvccon-06" v-show="svcNm == 'FL'">개통/유지보수사<span class="required">*</span></label>
												<div class="fcon2 multi" v-show="svcNm == 'FL'">
													<div class="select" id="basicSvccon-07">
														<span class="placeholder" ref="pn"><span class="fcGy">개통/유지보수사를 선택하세요</span></span>
														<div class="list">
															<ul v-show="svcNm != 'FL'">
															</ul>
															<ul v-for="item in partnerInfo" :key="item.displayseq">
																<li :value="item.code_cd" :data-name="item.code_nm" @click="selectValue($event, 'PN')">{{item.code_nm}}</li>
															</ul>
														</div>
													</div>
												</div>
												<label for="basicSvccon-06" class="lable_2 agc pd0" v-show="svcNm == 'FL'">기본공급장비<span class="required">*</span></label>
												<div class="fcon2 multi" v-show="svcNm == 'FL'">
													<div class="select" id="basicSvccon-07">
														<input type="email" id="paycon-05" v-model="equipGroupInfoDefault" readonly>
													</div>
												</div>
											</div>
											<div class="form-Item item06 label_2ea">
												<label for="basicSvccon-06">개통희망일<span class="required">*</span></label>
												<div class="fcon2 multi">
													<date-picker v-model="completedResvDateHh"
														:lang="lang"
														:disabled-date="disabledStartDate"
														format="YYYY/MM/DD"
														valueType="YYYY/MM/DD" type="date"></date-picker>
												</div>
												<label for="basicSvccon-05" class="lable_2 agc pd0" style="margin-left:328px;" v-show="svcNm == 'FL'">공급장비변경</label>
												<div class="fcon2 multi" v-show="svcNm == 'FL'">
													<div class="select" id="basicSvccon-07" style="width:100%;">
														<span class="placeholder" ref="equipGroupInfoNmSpan"><span class="fcGy">공급장비</span></span>
														<div class="list">
															<ul>
																<li  v-for="(item ,index) in equipGroupInfoNm" :key="index"
																	:value="item" 
																	:data-name="item" 
																	@click="selectValue($event, 'EGI')">{{item}}</li>
															</ul>
														</div>
													</div>
												</div>
												<label for="basicSvccon-05" class="lable_2 agc pd0" v-show="svcNm == 'FL'">라이선스타입</label>
												<div class="fcon2 multi" v-show="svcNm == 'FL'">
													<div class="select" id="basicSvccon-07" style="width:100%;">
														<span class="placeholder" ref="equipGroupInfoLtSpan"><span class="fcGy">라이선스타입</span></span>
														<div class="list">
															<ul>
																<li  v-for="(item ,index) in equipGroupInfoLt" :key="index"
																	:value="item.code_cd" 
																	:data-name="item.code_nm" 
																	:data-price="item.svc_add_chg"
																	@click="selectValue($event, 'EGL')">{{item.license_type}}</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											<p class="asterisk_red pdl320">
												※ 개통희망일 달력 현재일 기준 <span>7일이후 부터</span> 선택 가능합니다.
											</p>
											<p class="asterisk_red pdl320 mgb20" style="padding-left: 0px !important; margin-left: 140px;" v-show="svcNm == 'FL'">
												※ 기본 장비 외 장비 변경 시 추가 요금이 발생됩니다.
											</p>
											<div class="form-Item item06 label_2ea" v-show="svcNm == 'FL' && (svcTypeCd === '20' || svcTypeCd === '30')">
												<label for="basicSvccon-06"></label>
												<div class="fcon2 multi">
												</div>
												<label for="basicSvccon-06" class="lable_2 agc pd0">스위치 임대</label>
												<div class="fcon2 multi">
													<div class="select" id="basicSvccon-07" style="width:100%;">
														<span class="placeholder" ref="switchSpan"><span class="fcGy">임대 / 미임대 중 선택</span></span>
														<div class="list">
															<ul>
																<li value="0" 
																	data-name="임대" 
																	@click="selectValue($event, 'SW')">임대</li>
																<li value="1" 
																	data-name="미임대" 
																	@click="selectValue($event, 'SW')">미임대</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											<p class="asterisk_red pdl320 mgb20" style="padding-left: 0px !important; margin-left: 860px;" v-show="svcNm == 'FL' && (svcTypeCd === '20' || svcTypeCd === '30')">
												※ 2년 이상 계약 시 스위치 장비 무상으로 임대가 가능합니다. (2~5년 선택 후 스위치 임대 시 0원 청구)
											</p>
										</div>
									</div>
								</div>
								<!-- 03. 기본서비스정보 END -->

								<!-- 04. 부가서비스 정보 START -->
								<div id="section-044" class="join-section-04">
									<div class="que">
										<h3 class="sLtitle">부가 서비스 정보</h3>
									</div>
									<div class="anw addSvcInfo">
										<div class="lineBox form-list">
											<div class="form-Item item01 label_2ea">
												<label for="addSvccon-01">Cloud Direct</label>
												<div class="fcon">
													<div class="multi">
														<div class="fcon1">
															<div class="radio h52 two multi">
																<input type="radio" name="cloud" id="cloud_1" value="false" ref="cloudDirectF" checked disabled @click="cloudDirectChange($event, false)"/>
																<label for="cloud_1">선택안함</label>
																<input type="radio" name="cloud" id="cloud_2" value="true" ref="cloudDirectT" disabled @click="cloudDirectChange($event, true)"/>
																<label for="cloud_2">선택</label>
															</div>
														</div>
													</div>
													<div class="multi mgt20" v-show="cloudDirectFlag">
														<span class="stit">제공</span>
														<div class="select" id="addSvccon-01-03">
															<span class="placeholder" ref="cldSvc"><span class="fcGy">선택하세요</span></span>
															<div class="list">
																<ul v-for="item in cloudDirectInfo" :key="item.displayseq">
																	<li :value="item.code_cd" @click="selectValue($event, 'CD')" :data-name="item.code_nm">{{item.code_nm}}</li>
																</ul>
															</div>
														</div>
														<span class="stit2">속도</span>
														<div class="select" id="addSvccon-01-04">
															<span class="placeholder" ref="cldSvcSpd"><span class="fcGy">최대 속도</span></span>
															<div class="list">
																<ul v-for="item in cloudDirectSpeed" :key="item.code_cd">
																	<li :value="item.code_cd" @click="selectValue($event, 'CS')" :data-name="item.code_nm">{{item.code_nm}}</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
											<p class="asterisk_bk pdl320">
												※ Cloud 연결 제공 가능 서비스 : Flexline Premium / Basic<br />
												※ 각 서비스별 최대 속도 범위가 상이할 수 있습니다
											</p>
										</div>
									</div>
								</div>
								<!-- 04. 부가서비스 정보 END -->

								<!-- 05. 예상금액 START -->
								<div id="section-055" class="join-section-05">
									<div class="que">
										<h3 class="sLtitle">예상 금액</h3>
									</div>
									<div class="anw expect">
										<div class="grayBox con01">
											<p>1. 선택한 서비스의 예상 견적</p>
											<dl class="item01">
												<dt>서비스 이용료</dt>
												<dd>{{useAmt + equipUseChgSub + addsvcCntrctChg | comma}}<span>원</span><span>(부가세 별도)</span></dd>
											</dl>
										</div>
										<div class="lineBox con02 mgt40">
											<p>2. 서비스 별 이용 금액</p>
											<div class="item01 Sbddot1px">
												<dl>
													<dt><span>기본서비스</span>{{svcTypeNm}} {{lineSpeedInfoNmSel}} {{termCntrctTypeCdNmSel}}</dt>
													<dd>월<span class="fcPurple3 fw600">{{termCntrctChg | comma}}</span><span class="fw600">원</span></dd>
												</dl>
												<dl class="indent" v-show="uploadCost != 0">
													<dt><span>- 옵션</span>업로드 속도 증속</dt>
													<dd>월<span class="fcPurple3 fw600">{{uploadCost | comma}}</span><span class="fw600">원</span></dd>
												</dl>
												<dl class="indent" v-show="addIpCnt != 0">
													<dt><span>- 옵션</span>고정 IP 추가</dt>
													<dd>월<span class="fcPurple3 fw600">{{addsvcCntrctChg | comma}}</span><span class="fw600">원</span></dd>
												</dl>
												<dl class="indent" v-show="equipGroupInfoSel != '' && equpLicnsTypeCdCost != 0">
													<dt><span>- 옵션</span>공급장비 업그레이드</dt>
													<dd>월<span class="fcPurple3 fw600">{{equpLicnsTypeCdCost | comma}}</span><span class="fw600">원</span></dd>
												</dl>
												<dl class="indent" v-show="rentTypeCd != ''">
													<dt><span>- 옵션</span>스위치 임대</dt>
													<dd>월<span class="fcPurple3 fw600">{{rentSwitchChg | comma}}</span><span class="fw600">원</span></dd>
												</dl>
												<dl class="" v-show="cloudDirectSpeedSel != ''">
													<dt><span>부가서비스</span>Cloud Direct {{subSaNm}} {{cloudDirectSpeedNmSel}}</dt>
													<dd>월<span class="fcPurple3 fw600">{{equipUseChgSub | comma}}</span><span class="fw600">원</span></dd>
												</dl>
											</div>
											<div class="item02">
												<dl>
													<dt><span>설치비</span>{{svcTypeNm}} {{lineSpeedInfoNmSel}}</dt>
													<dd><span>일회성</span><span class="fcPurple3 fw600">{{cntrctInstChg | comma}}</span><span class="fw600">원</span></dd>
												</dl>
												<dl class="" v-show="cloudDirectSpeedSel != ''">
													<dt><span>설치비</span>Cloud Direct {{subSaNm}}</dt>
													<dd><span>일회성</span><span class="fcPurple3 fw600">{{cntrctInstChgSub | comma}}</span><span class="fw600">원</span></dd>
												</dl>
											</div>
										</div>
									</div>
								</div>
								<!-- 05. 예상금액 END -->

								<!-- 06. 판매자 정보 START -->
								<div id="section-066" class="join-section-06">
									<div class="que">
										<h3 class="sLtitle">판매자 정보</h3>
									</div>
									<div class="anw sellerInfo">
										<div class="lineBox form-list">
											<div class="form-Item item01">
												<label for="sellercon-01">가입유형</label>
												<div class="fcon">
													<div class="radio h52 four multi">
														<input type="radio" name="category" id="category_1" value="1" @click="selectValue($event, 'ST')" />
														<label for="category_1">고객</label>
														<input type="radio" name="category" id="category_2" value="2" @click="selectValue($event, 'ST')" checked>
														<label for="category_2">KT직원</label>
														<input type="radio" name="category" id="category_3" value="3" @click="selectValue($event, 'ST')">
														<label for="category_3">위탁영업점</label>
														<input type="radio" name="category" id="category_4" value="4" @click="selectValue($event, 'ST')">
														<label for="category_4">기타</label>
													</div>
												</div>
											</div>
											<div class="form-Item item02">
												<label for="sellercon-02">판매자명</label>
												<div class="fcon">
													<input type="text" id="sellercon-02" v-model="selNm" placeholder="판매자(영업점)명을 입력해주세요">
												</div>
											</div>
											<div class="form-Item item03">
												<label for="sellercon-03">판매자ID</label>
												<div class="fcon">
													<input type="text" id="sellercon-03" v-model="selId" placeholder="판매자 ID를 입력해주세요 (KT직원인 경우 사번)">
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- 06. 판매자 정보 END -->

								<!-- 07. 동의 START -->
								<div id="section-077" class="join-section-07">
									<div class="que">
										<h3 class="sLtitle">동의</h3>
									</div>
									<div class="anw agreejoin">
										<div class="lineBox">
											<div class="item01">
												<div class="checkbox">
													<input type="checkbox" id="cboxAll" class="hide" name="cboxAll" v-model="checkAll" v-on:click="checkAllBtn($event)">
													<label for="cboxAll">전체 동의<span class="fcRed">&nbsp;(필수)</span></label>
												</div>
											</div>
											<div class="item02">
												<div class="que-agree">
													<div class="checkbox">
														<input type="checkbox" id="checkbox1" class="hide" name="cbox" v-model="check1" v-on:click="checkBtn('check1')">
														<label for="checkbox1">개인정보 수집 및 이용에 동의 합니다<span class="fcRed">&nbsp;(필수)</span></label>
													</div>
													<div class="btn-agree" @click="openPdf(`https://flexon.kt.co.kr/gigaofficefile/kt_gigaoffice_agr_01.pdf`)">
														[내용보기]
													</div>
												</div>
												<div class="anw-agree">
													<div class="scrollInner">
														<a href="https://flexon.kt.co.kr/gigaofficefile/KT0003.pdf" class="btn-downData" target="_blank">[내용보기] 클릭 시 약관 내용 확인 가능</a>
														<br />
													</div>
												</div>
											</div>
											<div class="item03">
												<div class="que-agree">
													<div class="checkbox">
														<input type="checkbox" id="checkbox2" class="hide" name="cbox" v-model="check2" v-on:click="checkBtn('check2')">
														<label for="checkbox2">신용정보의 조회 및 이용에 대해 동의 합니다<span class="fcRed">&nbsp;(필수)</span></label>
													</div>
													<div class="btn-agree" @click="openPdf(`https://flexon.kt.co.kr/gigaofficefile/kt_gigaoffice_agr_02.pdf`)">
														[내용보기]
													</div>
												</div>
												<div class="anw-agree">
													<div class="scrollInner">
														[내용보기] 클릭 시 약관 내용 확인 가능<br />
													</div>
												</div>
											</div>
											<div class="item05">
												<div class="que-agree">
													<div class="checkbox">
														<input type="checkbox" id="checkbox4" class="hide" name="cbox" v-model="check3" v-on:click="checkBtn('check3')">
														<label for="checkbox4">고객 편의 제공을 위한 이용 및 취급위탁, 정보/광고 수신에 동의 합니다<span class="">&nbsp;(선택)</span></label>
													</div>
													<div class="btn-agree" @click="openPdf(`https://flexon.kt.co.kr/gigaofficefile/kt_gigaoffice_agr_03.pdf`)">
														[내용보기]
													</div>
												</div>
												<div class="anw-agree">
													<div class="scrollInner">
														[내용보기] 클릭 시 약관 내용 확인 가능<br />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- 07. 동의 END -->

								<!-- 신청 START -->
								<div class="btnBottomArea">
									<a class="btn-lg fix btn-primary" @click="goOrder()">신청</a>
									<a class="btn-lg btn-line02" @click="cancel()">취소</a>
								</div>
								<!-- 신청 END -->

							</div>
						</div>

					</div>
				</section>

			</main>
			
			<!-- POPUP START -->
			<!-- 고객정보 START -->
			<div id="pop-search" v-bind:class="popUserInfo ? 'show' : 'hide'" class="pop-wrap" >
				<div class="pop-inner pop-lg">
					<div class="pop-tit">
						고객정보
						<a  @click="popUserInfo=false" class="close pop-close-btn"></a>
					</div>
					<div class="pop-con">
						<div class="tbl_scroll">
							<table class="tbl_type04">
								<colgroup>
									<col style="width: 168px">
									<col style="width: 240px">
									<col style="width: 148px">
									<col style="width: 148px">
									<col style="width: *" />
									<col style="width: 63px">
								</colgroup>
								<thead>
									<tr>
										<th>고객구분</th>
										<th>법인/고객명</th>
										<th>사업자등록번호</th>
										<th>법인등록번호</th>
										<th>주소</th>
										<th>선택</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in userInfo" :key="item.custId" v-show="userInfo">
										<td>{{item.custNm}}</td>
										<td>{{item.corpNm}}</td>
										<td class="agc">{{item.custIdfyNo}}</td>
										<td class="agc">{{item.corpNo}}</td>
										<td>{{item.arnoAdrBasSbst + ' ' + item.arnoAdrDtlSbst}}</td>
										<td class="agc">
											<div class="checkbox tbl-ckRadio">
												<input type="radio" :id="item.custId" class="hide" name="ckSingle" @click="userInfoTempSave(item)">
												<label :for="item.custId"></label>
											</div>
										</td>
									</tr>
									<tr>
										<td colspan="6" style="text-align:center;" v-show="!userInfo">조회결과가 없습니다.</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="pop-btnBottomArea">
						<a class="btn-md01 btn-primary close" @click="closePopup('userInfo')">확인</a>
					</div>
				</div>
			</div> 
			<!-- 고객정보 END -->

			<!-- 설치주소 START -->
			<div id="pop-address" class="pop-wrap" v-bind:class="popLocationInfo ? 'show' : 'hide'">
				<div class="pop-inner pop-lg scrollInner-pop">
					<div class="pop-tit">설치장소
						<a @click="popLocationInfo=false" class="close pop-close-btn"></a>
					</div>
					<div class="pop-con">
						<div class="pop-address">
							<label for="pop-address02" class="form-labelh60 w80px agl">주소검색</label>
							<div class="input-search">
								<input type="search" id="pop-address02" placeholder="동 이름을 입력하세요." class="w490px" v-model="dong" @keyup.enter="openPopup('addrInfo')" ref="dong">
								<a @click="openPopup('addrInfo')" class="pop-open"></a>
							</div>
							<label for="pop-address03" class="form-labelh60 w100px">건물검색</label>
							<div class="input-search">
								<input type="search" id="pop-address03" placeholder="건물 이름을 입력하세요." class="w490px" v-model="bldgname" @keyup.enter="openPopup('buildingInfo')" ref="building">
								<a @click="openPopup('buildingInfo')" class="pop-open"></a>
							</div>
						</div>
					</div>
					<div class="pop-tit mgt60">건물조회 결과</div>
					<div class="pop-con">
						<div class="tbl_scroll">
							<table class="tbl_type04">
								<colgroup>
									<col style="width: 240px">
									<col style="width: *" />
									<col style="width: 167px">
								</colgroup>
								<thead>
									<tr>
										<th>건물명</th>
										<th>주소(지번)</th>
										<th>건물유형</th>
									</tr>
								</thead>
								<tbody>
									<tr v-show="buildingInfo">
										<td>{{buildingInfo.bldgname}}</td>
										<td>{{`${buildingInfo.addr1}${buildingInfo.addr2} ${detailAddr}`}}</td>
										<td>{{buildingInfo.bldgtypenm}}</td>
									</tr>
									<tr v-show="!buildingInfo">
										<td colspan="3" style="text-align: center;">
										조회 결과가 없습니다.
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="pop-btnBottomArea">
						<a @click="closePopup('locationInfo')" class="btn-md01 btn-primary close">확인</a>
					</div>
				</div>
			</div>
			<!-- 설치주소 END -->

			<!-- 주소검색 START -->
			<div id="pop-address02" class="pop-wrap pop-address02" v-bind:class="popAddrInfo ? 'show' : 'hide'">
				<div class="pop-inner pop-md">
					<div class="pop-tit">
						주소검색
						<a @click="popAddrInfo=false" class="close pop-close-btn"></a>
					</div>
					<div class="pop-con">
						<div>
							<div class="pop-address">
								<label for="pop-address02-01" class="form-labelh52 w80px agl">주소검색</label>
								<div class="input-search h52">
									<input type="search" id="pop-address02-01" placeholder="동 이름을 입력하세요." class="w500px h52" ref="dong2" v-model="dong" @keyup.enter="getCustAddressList">
									<a @click="getCustAddressList"></a>
								</div>
							</div>
							<p class="asterisk_bk">※ 검색 결과 선택하시면 자동으로 입력됩니다.</p>
							<div class="scrollInner-pop">
								<ul>
									<li v-for="item in addrList" :key="item.addrseq" @click="addrInfoTemp(item)"><a>{{item.addr}}</a></li>
								</ul>
							</div>
							<div class="pop-btnBottomArea">
								<a @click="closePopup('addrInfo')" class="btn-md01 btn-primary close">확인</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 주소검색 END -->

			<!-- 건물검색 START -->
			<div id="pop-address03" class="pop-wrap pop-address03" v-bind:class="popBuildingInfo ? 'show' : 'hide'">
				<div class="pop-inner pop-md">
					<div class="pop-tit">
						건물검색
						<a @click="popBuildingInfo=false" class="close pop-close-btn"></a>
					</div>
					<div class="pop-con">
						<div>
							<div class="pop-address">
								<label for="pop-address03-01" class="form-labelh52 w80px agl">주소</label>
								<div class="input-search h52">
									<input type="text" id="pop-address03-01" v-model="this.addrInfo.addr" class="w500px input-line" readonly>
								</div>
							</div>
							<div class="pop-address mgt20">
								<label for="pop-address03-02" class="form-labelh52 w80px agl">건물명</label>
								<div class="input-search h52">
									<input type="search" id="pop-address03-02" v-model="bldgname" placeholder="건물명을 입력하세요" class="w500px h52" @keyup.enter="getCustAddressBldgList">
									<a @click="getCustAddressBldgList"></a>
								</div>
							</div>
							<div class="pop-address mgt20">
								<label for="pop-address03-03" class="form-labelh52 w80px agl">상세주소</label>
								<div class="input-search h52">
									<input type="search" id="pop-address03-03" v-model="detailAddr" placeholder="상세주소를 입력하세요" class="w500px h52" @keyup.enter="closePopup('buildingInfo')">
								</div>
							</div>
							<p class="asterisk_bk">※ 검색 결과 선택하시면 자동으로 입력됩니다.</p>
							<div class="scrollInner-pop">
								<ul>
									<li v-for="item in buildingList" :key="item.bldgscode" @click="buildingInfoTemp(item)">{{item.addr1 + item.addr2}}</li>
								</ul>
							</div>
							<div class="pop-btnBottomArea">
								<a class="btn-md01 btn-primary close" @click="closePopup('buildingInfo')">확인</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 건물검색 END -->

			<!-- 결제수단 START -->
			<div id="pop-search02" class="pop-wrap" v-bind:class="popAccountInfo ? 'show' : 'hide'">
				<div class="pop-inner pop-lg">
					<div class="pop-tit">
						결제수단
						<a @click="popAccountInfo=false" class="close pop-close-btn"></a>
					</div>
					<div class="pop-con">
						<div class="tbl_scroll">
							<table class="tbl_type04">
								<colgroup>
									<col style="width: 136px" />
									<col style="width: 100px" />
									<col style="width: 100px" />
									<col style="width: 100px" />
									<col style="width: 148px" />
									<col style="width: 100px" />
									<col style="width: 100px" />
									<col style="width: *" />
									<col style="width: 63px" />
								</colgroup>
								<thead>
									<tr>
										<th>청구계정</th>
										<th>납부자명</th>
										<th>납부방법</th>
										<th>결제수단</th>
										<th>계좌번호/신용카드</th>
										<th>납부일</th>
										<th>명세서유형</th>
										<th>주소</th>
										<th>선택</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in accountInfo" :key="item.billAccId">
										<td>{{item.billAccId}}</td>
										<td>{{item.chagePayPrsnNm}}</td>
										<td>{{item.payMethCd}}</td>
										<td class="agc">{{item.payOrgNm}}</td>
										<td class="agc">{{item.payNo}}</td>
										<td class="agc">{{item.ddateDateDivCd}}</td>
										<td class="agc">{{item.acrndDspTypeCd}}</td>
										<td class="agc">{{item.arnoAdrBasSbst}}</td>
										<td class="agc">
											<div class="checkbox tbl-ckRadio">
												<input type="radio" :id="item.billAccId" class="hide" name="ckSingle" @click="accountInfoTempSave(item)">
												<label :for="item.billAccId"></label>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="pop-btnBottomArea">
						<a @click="closePopup('accountInfo')" class="btn-md01 btn-primary close">확인</a>
					</div>
				</div>
			</div>
			<!-- 결제수단 END -->
			<!-- POPUP END -->

			<section>
				<FooterLayout :totAmt="useAmt+equipUseChgSub+addsvcCntrctChg"></FooterLayout>
			</section>

		</div>
  
	</body>
</template>

<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'
import "@/assets/css/jquery.datetimepicker.css";
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'

export default {
	components: {
		HeaderLayout,
		FooterLayout,
		DatePicker
	},
	data () {
		return {
			// TAB
			active: 1,
			sectionNum: [
				'section-011',
				'section-022',
				'section-033',
				'section-044',
				'section-055',
				'section-066',
				'section-077'
			],
			// DATEPICKER
			completedResvDateHh: '',
			lang: {
				days: ["일", "월", "화", "수", "목", "금", "토"],
				months: [
					"1월",
					"2월",
					"3월",
					"4월",
					"5월",
					"6월",
					"7월",
					"8월",
					"9월",
					"10월",
					"11월",
					"12월",
				],
					yearFormat: "YYYY년",
					monthFormat: "MM월",
					monthBeforeYear: false,
			},
			// 고객 연락처 정보
			contactInfo: '',
			// 고객 정보
			userInfo: '',
			// 고객 정보 임시
			userInfoTemp: '',
			// 정보 구분 (사업자번호:8/법인번호:3)
			custNoType: '3',
			custNoTypeNm: '법인번호',
			// 선택한 고객정보
			userInfoSel: '',
			// 신청자 정보
			comNm: '',
			comTel: '',
			comEmail: '',
			// 신청 구분
			appDiv: '01',
			// 법인명
			partyName: '',
			// 사업자번호:08/법인번호:03
			partyDetailTypeCd: '',
			// 사업자번호
			partyIdNumber1: '',
			// 법인번호
			partyIdNumber2: '',
			// 임시 이메일
			selectEmail: '',
			// 임시 모바일
			selectMobile: '',
			// 임시 연락처
			selectPhone: '',
			// 첨부파일
			imageFile: {},
			// 설치주소
			fullAddr: '',
			// 동
			dong: '',
			// 빌딩
			bldgname: '',
			// 빌딩정보
			buildingInfo: '',
			// 상세주소
			detailAddr: '',
			// 동 정보
			addrList: '',
			// 주소검색 선택 결과
			addrInfo: '',
			// 빌딩검색 결과
			buildingList: '',
			// 납부방법
			payMethCd: '지로',
			// 명세서종류
			acrndDspTypeCd: '이메일',
			// 납부정보
			accountInfo: '',
			// 선택한 납부정보
			accountInfoSel: '',
			// 납부정보 임시
			accountInfoTemp: '',
			// 상품정보
			productInfo: '',
			// 상품분류코드
			svcNm: '',
			// 상품코드
			svcTypeCd: '',
			// 상품명
			svcTypeNm: '',
			// 기가인터넷 서비스 가능 지역 확인 버튼
			checkLocation: false,
			// 수용지역
			centerInfo: '',
			// 다운로드 속도 LIST
			lineSpeedInfo: '',
			// 다운로드 속도
			lineSpeedInfoSel: '',
			// 다운로드명
			lineSpeedInfoNmSel: '',
			// 업로드 속도 LIST
			lineUploadSpeedInfo: '',
			// 업로드 속도
			uploadSpeedInfoSel: '',
			// 업로드명
			uploadSpeedInfoNmSel: '',
			// 기본제공 IP
			basicIpCnt: 1,
			// 추가ip개수
			addIpCnt: 0,
			// 상품비용(기간비용+설치비)
			svcTotalChg: 0,
			// 기본서비스 이용료
			termCntrctChg: 0,
			// 계약기간코드
			termCntrctTypeCd: 'EQ0Y',
			// 계약기간 (할인율)
			termCntrctTypeCdNmSel: '(무약정)',
			// 설치비면제여부
			nrcExemptRsnCd: '',
			// 기본 협정요금
			useAmt: 0,
			// 장비임대료
			equipUseChgSub: 0,
			// 기본 설치비
			cntrctInstChg: 100000,
			// 상품정보 LIST
			productCostInfo: '',
			// IP당 가격 LIST
			subProductInfo: '',
			// 추가 IP 요금
			addsvcCntrctChg: 0,
			// 업로드속도 추가에 따른 비용
			uploadCost: 0,
			// 협력사 LIST
			partnerInfo: '',
			// 협력사
			partnerInfoSel: '',
			// 협력사명
			partnerInfoNmSel: '',
			// 공급장비 LIST
			equipGroupInfo: '',
			// 공급장비명 LIST
			equipGroupInfoNm: [],
			// 공급장비타입 LIST
			equipGroupInfoLt: [],
			// 기본장비명
			equipGroupInfoDefault: '',
			// 공급장비
			equipGroupInfoSel: '',
			// 공급장비명
			equipGroupInfoNmSel: '',
			// 공급장비 가격
			equpLicnsTypeCdCost: 0,
			// 스위치 정보 LIST
			switchInfo: {},
			// 스위치 코드
			rentTypeCd: '',
			// 스위치 명
			rentSwitchNm: '',
			// 스위치 가격
			rentSwitchChg: 0,
			// 클라우드 정보 LIST
			cloudDirectInfo: '',
			// 클라우드 속도
			cloudDirectSpeed: '',
			// cloud direct 속도
			cloudDirectSpeedSel: '',
			// cloud 속도명
			cloudDirectSpeedNmSel: '',
			// 클라우드 가격
			cloudDirectCostInfo: '',
			// cloud direct(기본서비스)-부가서비스정보
			cloudDirectInfoSel: '',
			// cloud direct 제공사명
			cloudDirectInfoNmSel: '',
			subSaNm: '',
			// cloud 설치비
			cntrctInstChgSub: 100000,
			// 총비용(설치비+장비임대료)
			svcTotalChgSub: 0,
			// 판매자(영업점)명
			selNm: '',
			// 판매자 ID
			selId: '',
			// 가입유형-판매자정보
			sellerTypeInfoSel: '2',
			// 가입유형-판매자정보명
			sellerTypeInfoNmSel: 'KT직원',
			// 고객정보팝업
			popUserInfo: false,
			// 설치주소팝업
			popLocationInfo: false,
			// 주소검색팝업
			popAddrInfo: false,
			// 건물검색팝업
			popBuildingInfo: false,
			// 납부자정보팝업
			popAccountInfo: false,
			// 서비스 가능지역
			svcLocSearch: false,
			// 클라우드 선택
			cloudDirectFlag: false,
			// 동의
			checkAll: false,
			// 개인정보 동의
			check1: false,
			// 신용정보 동의
			check2: false,
			// 고객 편의 제공 동의
			check3: false,
			// CDM 정보 구분
			cdmCustNoType: '3',
		}
	},
	mounted: async function () {
		this.completedResvDateHh = moment().add("7", "days").startOf("day").format("YYYY/MM/DD")
		await this.getGoSubProductInfo()
		await this.goProductCostInfo()
		await this.getPartnerList()
		await this.getCloudDirectList()
		await this.getCloudDirectSpeedList()
	},
	methods: {
		disabledStartDate(date) {
			// 휴일
			const arr = ['9/23, 12/25', '1/1', '10/5']
			const monthh = date.getMonth()
			const dayy = date.getDate();
			const holiday = arr.some(v => v==`${monthh}/${dayy}`)

			const day = new Date(date).getDay()
			return holiday || (day ===0 || day === 6) || (moment(date).format("YYYYMMDD") < moment().add("7", "days").startOf("day").format("YYYYMMDD"))
		},
		updateTab(num) {
			this.active = num
			var location = this.$refs.headerComponent.getOffset(document.getElementById(this.sectionNum[num-1])).top - 70
			window.scrollTo({top:location, behavior:'smooth'});
		},
		// 고객 연락처 정보 CALL
		async getAuthContactInfo () {
			this.contactInfo = ''
			this.userInfo = ''
			const payload = {
				token_id: sessionStorage.getItem('accessToken')
			}
			await this.$store.dispatch('gigaoffice/getAuthContactInfo', payload).then(res => {
				if (res.count === 0) {
					alert('등록된 고객정보가 없습니다. 고객정보를 등록해주세요.')
				} else {
					this.contactInfo = res.data.result
					if (this.contactInfo.partyIdNumber1 != null && this.contactInfo.partyIdNumber1 !== '') {
						this.partyIdNumber1 = this.contactInfo.partyIdNumber1
					}
					if (this.contactInfo.partyIdNumber2 != null && this.contactInfo.partyIdNumber2 !== '') {
						this.partyIdNumber2 = this.contactInfo.partyIdNumber2
					}

					// // 임시 저장
					this.selectEmail = this.contactInfo.email
					this.selectMobile = this.contactInfo.mobileNumber
					this.selectPhone = this.contactInfo.phoneNumber
					this.getUserInfo()

					this.partyIdNumber1 = res.data.result.partyIdNumber1
					this.partyIdNumber2 = res.data.result.partyIdNumber2
					this.partyName = res.data.result.partyName

					/// / 고객정보 에서 코드 값 보정 시퀀스 ///
					if (res.data.result.partyDetailTypeCd === '03' || res.data.result.partyDetailTypeCd === '06') {
						this.partyDetailTypeCd = 3
					}
					if (res.data.result.partyDetailTypeCd === '08') {
						this.partyDetailTypeCd = 8
					}
					if ((this.partyIdNumber2 === '') || (this.partyIdNumber2 === undefined) || (this.partyIdNumber2 === null)) {
						this.partyIdNumber2 = this.partyIdNumber1
					}
				}
			})
			.catch(error => {
				console.log(error)
			})
		},
		// 고객정보 조회 CALL
		async getUserInfo () {
			const payload = {}
			await this.$store.dispatch('gigaoffice/getUserInfo', payload).then(res => {
				if (res.data.code === '100') {
					if (res.data.count === '0') {
						return false
					}
					this.userInfo = res.data.result
				} else {
					alert(res.data.message)
				}
			})
			.catch (err => {
				console.log(err)
			})
		},
		// 청구정보 조회 CALL
		async getAccountListInfo () {
			this.accountInfo = ''
			try {
				const param = { custId: this.userInfoSel.encCustId }
				const res = await this.$store.dispatch('gigaoffice/getAccountListInfo', param)
				this.accountInfo = res
			} catch (err) {
				console.log(err)
			}
		},
		// 고객정보 임시 저장
		userInfoTempSave(userInfo) {
			this.userInfoTemp = userInfo
		},
		// 청구정보 임시 저장
		accountInfoTempSave(accountInfo) {
			this.accountInfoTemp = accountInfo
		},
		// 메인 서비스에 따른 세부 서비스 목록
		productInfoFilter(type) {
			this.svcNm = type
			this.goProductInfo()
		},
		// 상품정보 조회
		async goProductInfo () {
			try {
				const payload = {
					token_id: sessionStorage.getItem('accessToken')
					, type: this.svcNm
				}
				const res = await this.$store.dispatch('gigaoffice/getGoProductInfo', payload)
				this.productInfo = res.data.response.results
				
				this.dataInitStep1()
				this.dataInitStep2()
				this.dataInitStep3()
				this.dataInitStep4()
				this.dataInitStep5()
				this.dataInitStep6()

			} catch (err) {
				console.log(err)
			}
		},
		// 주소검색 조회
		async getCustAddressList () {
			const payload = {
				token_id: sessionStorage.getItem('accessToken'),
				dong: this.dong
			}
			await this.$store.dispatch('gigaoffice/getCustAddressList', payload).then(res => {
				// 초기화
				this.bldgname = ''
				// 동 세팅
				this.addrList = res.data.response.results
			})
			.catch(err => {
				console.log(err)
			})
		},
		// 주소검색 결과
		addrInfoTemp(addrInfo) {
			this.addrInfo = addrInfo
			setTimeout(() => {
				this.$refs.building.focus()
			}, 1)
			this.popAddrInfo = false
		},
		// 건물 검색
		async getCustAddressBldgList () {
			const payload = {
				token_id: sessionStorage.getItem('accessToken'),
				bldgname: this.bldgname,
				addrseq: this.addrInfo.addrseq
			}
			await this.$store.dispatch('gigaoffice/getCustBldgList', payload).then(res => {
				this.buildingList = res.data.response.results
			})
			.catch (err => {
				console.log(err)
			})
		},
		buildingInfoTemp(buildingInfo) {
			this.buildingInfo = buildingInfo
		},
		// 상품정보
		dataInitStep1() {
			this.$refs.detSvc.innerHTML = `<span class="fcGy">세부 서비스를 선택하세요</span>`
			this.svcTypeCd = ''
			this.svcTypeNm = ''
		},
		// 서비스 지역 ,수용 지역
		dataInitStep2() {
			// 서비스 가능 지역
			this.svcLocSearch = false
			this.checkLocation = false

			// 수용지역
			this.centerInfo = ''
		},
		// 속도
		dataInitStep3() {
			// 다운로드
			this.lineSpeedInfoSel = ''
			this.lineSpeedInfoNmSel = ''
			this.$refs.downSpdSpan.innerHTML = '<span class="fcGy">다운로드 속도</span>'

			// 업로드
			this.uploadSpeedInfoSel = ''
			this.uploadSpeedInfoNmSel = ''
			this.$refs.uploadSpdSpan.innerHTML = '<span class="fcGy">업로드 속도</span>'
			this.uploadCost = 0

			// 기본 제공 IP
			this.basicIpCnt = 1

			// 추가 IP
			this.addIpCnt = 0
			this.addsvcCntrctChg = 0
		},
		// 계약기간(할인율)
		dataInitStep4() {
			this.termCntrctTypeCd = 'EQ0Y'
			this.termCntrctTypeCdNmSel = '(무약정)'
			this.nrcExemptRsnCd = ''
		},
		dataInitStep5() {
			// 개통/유지보수사
			this.$refs.pn.innerHTML = '<span class="fcGy">개통/유지보수사를 선택하세요</span>'
			this.partnerInfoSel = ''
			this.partnerInfoNmSel = ''

			// 기본공급장비
			this.equipGroupInfoDefault = ''

			// 공급장비변경
			this.$refs.equipGroupInfoNmSpan.innerHTML = '<span class="fcGy">공급장비</span>'
			this.equipGroupInfoSel = ''
			this.equipGroupInfoNmSel = ''
			this.equpLicnsTypeCdCost = 0

			// 라이선스타입
			this.$refs.equipGroupInfoLtSpan.innerHTML = '<span class="fcGy">라이선스타입</span>'

			// 스위치 임대
			this.$refs.switchSpan.innerHTML = '<span class="fcGy">임대 / 미임대 중 선택</span>'
			this.rentTypeCd = ''
			this.rentSwitchNm = ''
			this.rentSwitchChg = 0
		},
		// 비용
		dataInitStep6() {
			this.svcTotalChg = 0
			this.cntrctInstChg = 100000
			this.useAmt = 0
			this.termCntrctChg = 0
			this.cntrctInstChgSub = 0
			this.equipUseChgSub = 0
			this.svcTotalChgSub = 0
		},
		// SELECT 선택시
		async selectValue(e, type) {
			let v = e.currentTarget.getAttribute('value')
			let vnm = e.currentTarget.getAttribute('data-name')
			let i = e.currentTarget.getAttribute('data-index')

			switch (type) {
				case 'DS' :
					if(this.svcTypeCd == v) return false

					this.dataInitStep2()
					this.dataInitStep3()
					this.dataInitStep4()
					this.dataInitStep5()
					this.dataInitStep6()

					this.svcTypeCd = v
					this.svcTypeNm = vnm

					await this.getLineSpeedList()
					// if(this.svcNm === 'FL'){
					// 	await this.getEquipGroupList()
					// }
					if(this.svcTypeCd === '20' || this.svcTypeCd === '30'){
						await this.getRentSwitch()
					}

					switch (v) {
						// basic
						case '11' :
							this.basicIpCnt = 2
							this.svcLocSearch = true
							break
						// premium
						case '20' :
							this.basicIpCnt = 16
							await this.$store.dispatch('gigaoffice/getGoCenter', this.fullAddr).then(res => {
								this.centerInfo = res.data.response.centerName
							})
							.catch (err => {
								console.log(err)
							})
							break
						// premium+
						case '30' :
							// this.basicIpCnt = 128
							this.basicIpCnt = 32
							break
						// standard or compact
						case '31' :
						case '32' :
							this.basicIpCnt = 8
							await this.$store.dispatch('gigaoffice/getGoCenter', this.fullAddr).then(res => {
								this.centerInfo = res.data.response.centerName
							})
							.catch (err => {
								console.log(err)
							})
						break;
						// simple
						case '34' :
							this.basicIpCnt = 4
							await this.$store.dispatch('gigaoffice/getGoCenter', this.fullAddr).then(res => {
								this.centerInfo = res.data.response.centerName
							})
							.catch (err => {
								console.log(err)
							})
						break;
					}

					if(this.svcNm == 'FL' && this.svcTypeCd != '30') {
						this.$refs.cloudDirectT.disabled = false
						this.$refs.cloudDirectF.disabled = false
					} else {
						this.cloudDirectFlag = false
						this.cloudDirectInfoSel = ''
						this.cloudDirectSpeedSel = ''
						this.$refs.cloudDirectT.disabled = true
						this.$refs.cloudDirectF.disabled = true
						this.$refs.cloudDirectF.checked = true
						this.$refs.cloudDirectT.checked = false
					}

					break
				// 다운로드 속도
				case 'LS' :
					this.lineSpeedInfoSel = v
					this.lineSpeedInfoNmSel = `(${vnm})`
					this.$refs.downSpdSpan.innerHTML = `<span class="fcGy">다운로드 속도</span>`

					this.useAmt = 0

					this.dataInitStep4()
					this.dataInitStep5()
					this.dataInitStep6()
					
					if(this.svcNm == 'FL') {
						// 베이직
						if (this.svcTypeCd == '11') {
							this.$refs.uploadSpdSpan.innerHTML = `최대 ${vnm}`
							this.$refs.uploadSpdSpan.disabled = true
							this.uploadSpeedInfoSel = v
							this.uploadSpeedInfoNmSel = `(${vnm})`
						// 프리미엄
						} else if(this.svcTypeCd == '20') {
							this.$refs.uploadSpdSpan.disabled = false

							//업로드 속도 제한
							const lineUploadSpeedInfoInit = JSON.parse(JSON.stringify(this.lineSpeedInfo))
							let lsDisplayseq = lineUploadSpeedInfoInit.find(item => item.code_cd == this.lineSpeedInfoSel).displayseq
							const lineUploadSpeedInfoCopy = lineUploadSpeedInfoInit.filter(item => item.displayseq <= lsDisplayseq)
							this.lineUploadSpeedInfo = lineUploadSpeedInfoCopy
							this.$refs.uploadSpdSpan.innerHTML = `<span class="">200M (기본)</span>`
							this.uploadSpeedInfoSel = '80'
							this.uploadSpeedInfoNmSel = `(200M)`
						// 프리미엄+
						} else if(this.svcTypeCd == '30') {
							this.$refs.uploadSpdSpan.innerHTML = `${vnm}`
							this.$refs.uploadSpdSpan.disabled = true
							this.uploadSpeedInfoSel = v
							this.uploadSpeedInfoNmSel = `(${vnm})`

							if(v == '3A' || v == '80' || v == '85' || v == '90'){
								this.basicIpCnt = 32
							}else{
								this.basicIpCnt = 128
							}
						}
						await this.getEquipGroupList()
					} else {
							this.$refs.uploadSpdSpan.disabled = true
							this.uploadSpeedInfoSel = '80'
							this.uploadSpeedInfoNmSel = `(200M)`
							this.$refs.uploadSpdSpan.innerHTML = `<span class="fcGy">200M</span>`
					}

					this.addIpCnt = 0
					this.addsvcCntrctChg = 0

					this.getCost()

					break
				// 업로드 속도
				case 'LSU' :
					this.uploadSpeedInfoSel = v
					this.uploadSpeedInfoNmSel = `(${vnm})`
					// let uploadSpeedIndex = i
					// Premium 업로드 200M 부터 100M 단위 증속 시, 10만원씩 추가
					if(this.svcTypeCd == '20') {
						if(i > 2) {
							this.uploadCost = (i-2) * 100000 
						} else if(i == 2) {
							this.uploadCost = 0
						} else {
							return false
						}
					}
					this.getCost()
					break;
				// 계약기간 (할인율)
				case 'TE' :
					this.dataInitStep5()
					this.dataInitStep6()
					
					this.termCntrctTypeCd = v
					this.termCntrctTypeCdNmSel = `(${vnm})`
					if(this.termCntrctTypeCd.substr(2,1) != '1' && this.termCntrctTypeCd.substr(2,1) != '2') {
						this.nrcExemptRsnCd = '86'
					} else {
						this.nrcExemptRsnCd = ''
					}

					// 스위치 정보
					this.rentTypeCd = ''
					this.rentSwitchNm = ''
					this.rentSwitchChg = 0
					if(this.svcTypeCd === '20' || this.svcTypeCd === '30'){
						await this.getRentSwitch()
					}

					await this.getEquipGroupList()

					this.getCost()
					break
				// 공급장비명 선택
				case 'EGI' :
					this.equipGroupInfoLt = []
					this.equipGroupInfo.forEach( (item, index) => {
						let temp = JSON.parse(JSON.stringify(item))
						if(temp.code_nm.includes(v)){
							this.equipGroupInfoLt.push(temp)
						}
					})
					this.$refs.equipGroupInfoLtSpan.innerHTML = this.equipGroupInfoLt[0].license_type
					this.equipGroupInfoSel = this.equipGroupInfoLt[0].code_cd
					this.equipGroupInfoNmSel = this.equipGroupInfoLt[0].code_nm
					this.equpLicnsTypeCdCost = this.equipGroupInfoLt[0].svc_add_chg
					this.getSum()
					break
				// 라이선스타입
				case 'EGL' :
					this.equipGroupInfoSel = v
					this.equipGroupInfoNmSel = vnm
					this.equpLicnsTypeCdCost = e.currentTarget.getAttribute('data-price')
					this.getSum()
					break
				// 스위치 임대
				case 'SW' :
					if(v === '0'){
						this.rentTypeCd = this.switchInfo.code_cd
						this.rentSwitchNm = this.switchInfo.code_nm
						this.rentSwitchChg = this.switchInfo.rent_switch_chg
					}else{
						this.rentTypeCd = ''
						this.rentSwitchNm = ''
						this.rentSwitchChg = 0
					}
					this.getSum()
					break
				// 클라우드 장비
				case 'CD' :
					this.cloudDirectInfoSel = v
					this.cloudDirectInfoNmSel = vnm
					this.getCloudDirectCostInfo()
					this.subSaNm = vnm
					break
				// 클라우드 속도
				case 'CS' :
					this.cloudDirectSpeedSel = v
					this.cloudDirectSpeedNmSel = `(${vnm})`
					this.getSubCost()
					break;
				case 'ST' :
					this.sellerTypeInfoSel = v
					this.sellerTypeInfoNmSel = e.target.nextElementSibling.innerHTML
					// console.log('sellerTypeInfoNmSel : ', this.sellerTypeInfoNmSel)
					break;
				case 'PN' :
					this.partnerInfoSel = v
					this.partnerInfoNmSel = vnm
					break
			}
		},
		// 회선속도코드 조회
		async getLineSpeedList() {
			const res = await this.getComCodeList('LS')
			var result = res.data.response.results
			// GIGAoffice Standard : 1G
			if(this.svcTypeCd == '31') {
				this.lineSpeedInfo = result.filter((item) => item.code_cd == '39')
			// GIGAoffice Compact : 500M
			} else if (this.svcTypeCd == '32') {
				this.lineSpeedInfo = result.filter((item) => item.code_cd == '95')
			// GIGAoffice Simple : 200M
			} else if (this.svcTypeCd == '34') {
				this.lineSpeedInfo = result.filter((item) => item.code_cd == '80')
			// Flexline Basic: 100M, 200M, 500M, 1G
			} else if (this.svcTypeCd == '11') {
				this.lineSpeedInfo = result.filter((item) => item.code_cd == '3A' || item.code_cd == '80' || item.code_cd == '95' || item.code_cd == '39')
			// Flexline Premium: 200M ~ 1G
			} else if (this.svcTypeCd == '20') {
				this.lineSpeedInfo = result.filter((item) => item.code_cd != '3A')
			// Flexline Premium+: 100M ~ 1G
			} else if(this.svcTypeCd == '30') {
				this.lineSpeedInfo = result
			}
			this.lineUploadSpeedInfo = this.lineSpeedInfo
		},
		// IP 추가 단위 계산
		getAddIpCnt(type) {
			let minIpCnt = 0;
			let maxIpCnt = 0;
			let vIpCnt = 0;
			switch(this.svcTypeCd) {
				// basic
				case '11' :
					minIpCnt = 1
					maxIpCnt = 3
					vIpCnt = 1
					break;
				// premium / premium+
				case '20' :
					minIpCnt = 16
					// maxIpCnt = 256
					maxIpCnt = 64
					vIpCnt = 16
					break;
				case '30' :
					if(this.lineSpeedInfoSel == '3A' || this.lineSpeedInfoSel == '80' || this.lineSpeedInfoSel == '85' || this.lineSpeedInfoSel == '90'){
						maxIpCnt = 64
					}else{
						maxIpCnt = 128
					}
					minIpCnt = 16
					// maxIpCnt = 256
					vIpCnt = 16
					break;
				// standard / compact
				case '31' :
				case '32' :
					minIpCnt = 8
					maxIpCnt = 256
					if (type === '+') {
						if (this.addIpCnt < maxIpCnt) {
							this.addIpCnt *= 2
						}
					// MINUS
					} else {
						if(this.addIpCnt > minIpCnt) {
							this.addIpCnt /= 2
						} else {
							return false
						}
					}
					break;
				// simple
				case '34' :
				break;
			}
			if(type === '+') {
				if(this.addIpCnt < maxIpCnt ) this.addIpCnt += vIpCnt
			} else {
				if(this.addIpCnt > 0) this.addIpCnt -= vIpCnt
			}
			this.getAddIpCost()
			this.getCost()
		},
		// 상품가격 계산
		async getCost () {
			await this.productCostInfo.forEach(item => {
				if (item.svc_type_cd === this.svcTypeCd) {
					if (item.term_cntrct_type_cd === this.termCntrctTypeCd) {
						if(this.svcTypeCd === '11' || this.svcTypeCd === '20' || this.svcTypeCd === '30') {
							if (item.line_spec_cd === this.lineSpeedInfoSel) {
								this.cntrctInstChg = item.cntrct_inst_chg
								this.termCntrctChg = item.term_cntrct_chg
								this.svcTotalChg = item.svc_total_chg
							}
						} else {
							this.cntrctInstChg = item.cntrct_inst_chg
							this.termCntrctChg = item.term_cntrct_chg
							this.svcTotalChg = item.svc_total_chg
						}
					}
				}
			})
			this.getSum()
		},
		// clouddirect 가격
		getSubCost() {
			let temp = this.useAmt
			this.cloudDirectCostInfo.forEach(item => {
				if(item.lineSpecCd == this.cloudDirectSpeedSel){
					this.cntrctInstChgSub = item.cntrctInstChg
					this.equipUseChgSub = item.equipUseChg
					this.svcTotalChgSub = item.svcTotalChg
				}
			})
			this.getSum()
		},
		//총 가격 useAmt
		getSum() {
			// ICIS  연동 요금 3가지 (중요)
			// 1) 기본 협정요금 useAmt: “기본서비스 + 공급장비 업그레이드 + 업로드 속도 변경 요금 (Premium만 해당)” 합한 값
			// 2) 추가 IP 요금 addsvcCntrctChg
			// 3) Cloud Direct 요금 equipUseChgSub

			if(!this.svcTypeCd) return false
			if(!this.lineSpeedInfoSel) return false
			// 총 가격
			var sum = parseInt(this.svcTotalChg)
			// 업로드 속도 변경요금
			sum += parseInt(this.uploadCost)
			// 장비 업그레이드 요금
			sum += parseInt(this.equpLicnsTypeCdCost)
			// 스위치 장비 요금
			sum += parseInt(this.rentSwitchChg)
			// 설치비는 제외시켜야함
			sum -= parseInt(this.cntrctInstChg)
			this.useAmt = parseInt(sum)
		},
		// 상품정보
		async goProductCostInfo () {
			try {
				const payload = {
					token_id: sessionStorage.getItem('accessToken')
				}
				const res = await this.$store.dispatch('gigaoffice/getGoProductCostInfo', payload)
				this.productCostInfo = res.data.response.results
			} catch (err) {
				console.log(err)
			}
		},
		// ip당 가격
		async getGoSubProductInfo () {
			try {
				const payload = {
					token_id: sessionStorage.getItem('accessToken')
				}
				const res = await this.$store.dispatch('gigaoffice/getGoSubProductInfo', payload)
				this.subProductInfo = res.data.response.results
			} catch (err) {
				console.log(err)
			}
		},
		// 추가 ip 가격 계산
		async getAddIpCost() {
			await this.subProductInfo.forEach(item => {
				if (item.svc_type_cd === this.svcTypeCd) {
					if (item.addsvc_req_cnt === this.addIpCnt) {
						this.addsvcCntrctChg = item.addsvc_cntrct_chg
					} else if(this.addIpCnt == 0){
						this.addsvcCntrctChg = 0
					}
				}
			})
		},
		// 협력사 조회
		async getPartnerList() {
			const res = await this.getComCodeList('PN')
			this.partnerInfo = res.data.response.results
		},
		// 공급장비 조회
		async getEquipGroupList() {
			const payload = {
				lineSpeed: this.lineSpeedInfoSel
				, svcTypeCd: this.svcTypeCd
			}
			this.equipGroupInfoSel = ''
			const res = await this.$store.dispatch('gigaoffice/getServiceEquip', payload)
			this.equipGroupInfo = res.data.results

			this.equipGroupInfoDefault = this.equipGroupInfo[0].code_nm
			this.equipGroupInfoSel = this.equipGroupInfo[0].code_cd
			this.equipGroupInfoNmSel = this.equipGroupInfo[0].code_nm
			this.equpLicnsTypeCdCost = this.equipGroupInfo[0].svc_add_chg

			let arr = []
			this.equipGroupInfo.forEach( (item, index) => {
				let temp = JSON.parse(JSON.stringify(item))
				arr.push(temp.code_nm.split('(')[0])
			})
			this.equipGroupInfoNm = [...new Set(arr)]
		},
		// 스위치 장비 조회
		async getRentSwitch() {
			this.$refs.switchSpan.innerHTML = `<span class="fcGy">임대 / 미임대 중 선택</span>`
			const params = {
				svcTypeCd: this.svcTypeCd, 
				termCntrctTypeCd: this.termCntrctTypeCd
			}
			const res = await this.$store.dispatch('gigaoffice/getRentSwitch', params)
			this.switchInfo = res.data.results[0]
		},
		// CloudDirect 조회
		async getCloudDirectList() {
			const res = await this.getComCodeList('CD')
			this.cloudDirectInfo = res.data.response.results
		},
		// CloudDirect 속도 조회
		async getCloudDirectSpeedList() {
			const res = await this.getComCodeList('BS')
			this.cloudDirectSpeed = res.data.response.results
		},
		// cloud direct 상품별 가격
		async getCloudDirectCostInfo () {
			try {
				const payload = {
					bgSvcTypeCd: this.cloudDirectInfoSel
				}
				const res = await this.$store.dispatch('gigaoffice/getCloudDirectCostInfo', payload)
				this.cloudDirectCostInfo = res.data.response.list
			} catch (err) {
				console.log(err)
			}
		},
		// 공통코드 조회
		async getComCodeList(group_cd, code_cd) {
			let payload = {
				group_cd : group_cd,
				code_cd : code_cd 
			}
			let response = {}
			await this.$store.dispatch('gigaoffice/getComCodeList', payload).then(res => {
				response = res
			})
			.catch(err => {
				console.log(err)
			})
			return response
		},
		cloudDirectChange(e, boolean) {
			//선택
			if(boolean) {
				if(this.svcTypeCd == '11' || this.svcTypeCd == '20') {
					this.cloudDirectFlag = true
				}
			//선택안함
			} else {
				this.cloudDirectFlag = false
				this.cloudDirectInfoSel = ''
				this.cloudDirectSpeedSel = ''
				this.$refs.cldSvc.innerHTML = `<span class="fcGy">선택하세요</span>`
				this.$refs.cldSvcSpd.innerHTML = `<span class="fcGy">최대 속도</span>`
				this.cntrctInstChgSub = 0
				this.equipUseChgSub = 0
				this.svcTotalChgSub = 0
			}
		},
		// 전체동의 버튼
		checkAllBtn(event) {
			if(event.target.checked){
				document.querySelectorAll('input[name="cbox"]').forEach(item => {
					item.checked = true
				})
				this.check1 = true
				this.check2 = true
				this.check3 = true
			}else{
				document.querySelectorAll('input[name="cbox"]').forEach(item => {
					item.checked = false
				})
				this.check1 = false
				this.check2 = false
				this.check3 = false
			}
		},
		// 동의버튼
		checkBtn(type) {
			if(type === 'check1') this.check1 === true ? false : true
			if(type === 'check2') this.check2 === true ? false : true
			if(type === 'check3') this.check3 === true ? false : true

			let cnt = 0
			const tot = document.querySelectorAll('input[name="cbox"]').length
			document.querySelectorAll('input[name="cbox"]').forEach(item => {
				if(item.checked){
					cnt++
				}
			})
			if(cnt === tot){
				document.querySelector('input[name="cboxAll"]').checked = true
			}else{
				document.querySelector('input[name="cboxAll"]').checked = false
			}
		},
		// 청약신청
		goOrder() {
			if(!this.checkValidation()) {
				return false
			}
			if(!confirm('상품을 신청하시겠습니까?')) {
				return false
			}
			this.postGoOrderSubscriptionReceipt()
		},
		// 청약 신청
		async postGoOrderSubscriptionReceipt() {
			let param = {
				tokenId: sessionStorage.getItem('accessToken')
				, userId: sessionStorage.getItem('userId')
				// 신청유형 01:신규 / 02:변경
				, acptType : '01'
				// 법인번호 : 3 / 사업자번호 : 8
				, custNoType : this.custNoType
				// 법인번호(13자리) / 사업자번호(10자리) corpNo custIdfyNo
				, custNo : this.custNoType == '8' ? this.userInfoSel.custIdfyNo : this.userInfoSel.corpNo
				// 법인명(고객명)
				, bizrCrprNm : this.userInfoSel.corpNm
				// 법인등록번호(생년월일)
				, crprNo : this.userInfoSel.corpNo
				// 대표자명
				, aplcntNm : this.userInfoSel.reprNm
				// 사업자등록번호
				, bizrRgstNo : this.userInfoSel.custIdfyNo
				// 신청자 이름
				, comNm : this.comNm
				// 신청자 연락처
				, comTel : this.comTel
				// 신청자 이메일
				, comEmail : this.comEmail
				// 신청구분 - 본인신청 : 01 / 대리신청 : 02
				, aplcntRltn: this.appDiv
				// 설치장소 상세주소
				, addrRef : this.detailAddr
				// 빌딩정보의 dongcode
				, dongCd : this.addrInfo.dongcode
				// bldg list의 addrtype '일반' or '산' 17
				, addrNoType : this.buildingInfo.addr_no_type
				// bldg list addr num 18
				, addrNo : this.buildingInfo.addr_no
				// bldg list addrsubnum 19
				, addrHo : this.buildingInfo.addr_ho
				// bldg list 정보의 bldgsvrcode
				, addrBldgId : this.buildingInfo.bldgsvrcode
				// 청구정보ID-납부정보
				, billId : this.accountInfoSel.billAccId
				// 가입유형-기본 서비스 정보 ( - 신규신청시 빈값)
				, saId : ''
				// 서비스명
				, svcTypeCd : this.svcTypeCd
				// 다운로드속도
				, lineSpecCd : this.lineSpeedInfoSel
				// 업로드속도
				, uploadSpd : this.uploadSpeedInfoSel
				// 추가IP수
				, addIpCnt : this.addIpCnt
				// 기본제공IP수
				, ipAddrCnt : this.basicIpCnt
				// 계약기간-기본 서비스 정보
				, termCntrctTypeCd: this.termCntrctTypeCd
				// 대여장비 - 스위치정보
				, subEquipCd : this.rentTypeCd
				// 협력사
				, maintAgentCd : this.partnerInfoSel
				// 개통희망일
				, completedResvDateHh : this.completedResvDateHh.replaceAll('/','')
				// cloud direct(기본서비스)-부가서비스정보
				, subSaCd : this.cloudDirectFlag ? this.cloudDirectInfoSel : ''
				// cloud direct 속도
				, bugaSpdCd: this.cloudDirectSpeedSel
				// 부가서비스 없으면 null로
				, bugaUseAmt : this.cloudDirectFlag ? this.svcTotalChgSub : ''
				// 판매자 / 고객 (가입유형) / KT방배지점 (판매자,영업점명) / asb (판매자 ID)
				, sellerInfo : ''
				// 신규청약 (-remark + / + com_nm + / + com_tel + / + com_email)
				, remark:'신규청약'
				// 가입유형-판매자정보
				, selType : this.sellerTypeInfoSel
				// 가입유형-판매자정보
				, selTypeNm : this.sellerTypeInfoNmSel
				// 판매자(영업점)명-판매자정보
				, selNm : this.selNm
				// 판매자ID-판매자정보
				, selId : this.selId
				// 신청자 집 전화 번호
				, aplcntTelNo : this.selectPhone
				// 신청자 휴대폰 번호
				// , aplcntMobileNo : this.selectMobile
				, aplcntMobileNo : this.comTel
				// 신청자 Email
				, aplcntEmail : sessionStorage.getItem('userEmail')
				// userSvcInfo custId 고객정보 조회시 cust_ind 배열중 하나 3
				, custInd : this.userInfoSel.custId
				, nflCustNm : this.userInfoSel.custNm
				// 기가 오피스 : 15 고정 : 12 Flexline : 16
				, prdcType : this.svcNm == 'FL' ? '16' : '15'
				// 기본 협정요금 useAmt: 기본서비스 + 공급장비 업그레이드 + 업로드 속도 변경 요금 (Premium만 해당) 합한 값 number
				, useAmt : parseInt(this.useAmt)
				// 연락 email주소
				, cntcEmailAddr : this.accountInfoSel.emailAdr
				// 처리상태코드
				, trtmStatCd : '1'
				// GO센터명
				, goOfcName : this.centerInfo
				, equipGrpCd : this.equipGroupInfoSel
				// 고정IP 협정요금
				, ipUseAmt : this.addIpCnt != '0' ? parseInt(this.addsvcCntrctChg) : 0
				// IP 협정요금 부과여부 // 여 1, 부 null
				, ipChargeCd : this.addIpCnt != '0' ? '1' : ''
				// 설치비면제여부 // ( '86' - 3년이상 약정 )
				, nrcExemptRsnCd : this.nrcExemptRsnCd

				// Email 발송을 위한 추가
				// 사업자번호:8/법인번호:3, custNum 사업자번호(10자리)/법인번호(13자리)
				, custNoTypeNm : this.custNoTypeNm
				// 신청구분명
				, aplcntRltnNm: this.appDiv == '01' ? '본인신청' : '대리신청'
				// 설치주소
				, fullAddr : this.fullAddr
				// 납부방법
				, payMethCd : this.payMethCd
				// 은행(카드사)명
				, payOrgNm : this.accountInfoSel.payOrgNm
				// 계좌(카드)번호
				, payNo : this.accountInfoSel.payNo
				// 예금(카드)주명
				, chagePayPrsnNm : this.accountInfoSel.chagePayPrsnNm
				// 명세서 종류
				, acrndDspTypeCd : this.acrndDspTypeCd
				// 세부서비스명
				, svcTypeNm : this.svcTypeNm
				// 다운로드명
				, lineSpeedInfoNmSel : this.lineSpeedInfoNmSel
				// 업로드명
				, uploadSpeedInfoNmSel : this.uploadSpeedInfoNmSel
				// 계약기간 (할인율)
				, termCntrctTypeCdNmSel : this.termCntrctTypeCdNmSel
				// 개통/유지보수사명
				, partnerInfoNmSel : this.partnerInfoNmSel
				// 공급장비명
				, equipGroupInfoNmSel : this.equipGroupInfoNmSel.replace(' (기본)' ,'')
				, acptTypeNm : '신규'
				, prdcTypeNm : this.svcNm == 'FL' ? 'Flexline' : 'GiGA office'
				, centerInfoNmSel : this.centerInfo
				// cloud 제공사명
				, cloudDirectInfoNmSel : this.cloudDirectInfoNmSel
				// cloud 속도명
				, cloudDirectSpeedNmSel : this.cloudDirectSpeedNmSel
				// cloud 부가서비스 이용료
				, equipUseChgSub : this.equipUseChgSub
				// 기본서비스 이용료
				, termCntrctChg : this.termCntrctChg
				// 업로드 속도 이용료
				, uploadCost : this.uploadCost
				// 공급 장비 업그레이드 이용료
				, equpLicnsTypeCdCost : this.equpLicnsTypeCdCost
				// 기본설치비
				, cntrctInstChg : this.cntrctInstChg
				// 부가서비스 설치비
				, cntrctInstChgSub : this.cntrctInstChgSub
				, rentTypeCd : this.rentTypeCd
				, rentSwitchNm : this.rentSwitchNm
				, rentSwitchChg : this.rentSwitchChg
				, otpAuthYn: 'N'
				, cdmCustNoType: this.cdmCustNoType
			}

			const formData = new FormData()
			formData.append('flexLineInsertReqVo', new Blob([JSON.stringify(param)], {type: "application/json"}))
			for(let key in this.imageFile) {
				formData.append(key, this.imageFile[key])
			}
			await this.$store.dispatch("gigaoffice/postGoOrderSubscriptionReceipt", formData).then(res => {
				if(res.data.code == '100' || res.data.code == '400') {
					alert('서비스 신청이 완료되었습니다.')
					this.$router.push('/')
				}else if(res.data.code == '104') {
					alert(res.data.message)
				}
			})
			.catch(error => {
				this.$store.commit("END_SPINNER");
				console.log(error);
			});
		},
		checkValidation() {
			if(this.userInfoSel == '') {
				alert('기본정보를 선택해주세요.')
				return false
			}
			if(this.comNm == '') {
				alert('신청자 이름을 입력해주세요.')
				this.$refs.comNm.focus()
				return false
			}
			if(this.comTel == '') {
				alert('신청자 연락처를 입력해주세요.')
				this.$refs.comTel.focus()
				return false
			}
			if(this.comEmail == '') {
				this.$refs.comEmail.focus()
				alert('신청자 이메일을 입력해주세요.')
				return false
			}
			if(this.appDiv == '01'){
				if(this.imageFile['imgBiz'] == undefined){
					alert('사업자등록증을 첨부해주세요.')
					return false
				}
				if(this.imageFile['imgCeo'] == undefined){
					alert('대표자신분증을 첨부해주세요.')
					return false
				}
			}
			if(this.appDiv == '02'){
				if(this.imageFile['imgBiz'] == undefined){
					alert('사업자등록증을 첨부해주세요.')
					return false
				}
				if(this.imageFile['imgAge'] == undefined){
					alert('대리인신분증을 첨부해주세요.')
					return false
				}
				if(this.imageFile['imgSeal'] == undefined){
					alert('인감증명서을 첨부해주세요.')
					return false
				}
				if(this.imageFile['imgWar'] == undefined){
					alert('위임장을 첨부해주세요.')
					return false
				}
			}
			if(this.addrInfo == '') {
				alert('설치주소를 입력해주세요.')
				return false
			}
			if(this.accountInfoSel == '') {
				alert('납부정보 - 결제수단을 선택해주세요.')
				return false
			}
			if(this.svcTypeCd == '') {
				alert('서비스를 선택해주세요.')
				return false
			}
			// if(this.svcTypeCd == '20') {
			// 	if(this.centerInfo_sel.code_cd == '') {
			// 		alert('GO센터를 선택해주세요.')
			// 		return false
			// 	}
			// }
			if(this.svcLocSearch) {
				if(!this.checkLocation) {
					alert('서비스 가능지역 여부를 확인해주세요.')
					return false
				}
			}
			if(this.lineSpeedInfoSel == '') {
				alert('다운로드 속도를 선택해주세요.')
				return false
			}
			if(this.svcNm == 'FL') {
				if(this.partnerInfoSel == '') {
					alert('협력사를 선택해주세요.')
					return false
				}
				if(this.equipGroupInfoSel == '') {
					alert('공급장비를 선택해주세요.')
					return false
				}
			}
			if(this.completedResvDateHh == '') {
				alert('개통희망일을 선택해주세요.')
				return false
			}
			if(!this.check1) {
				alert('개인정보 수집 및 이용 동의에 체크해주세요.')
				return false
			}
			if(!this.check2) {
				alert('신용정보의 조회 및 이용 동의에 체크해주세요.')
				return false
			}
			return true
		},
		// 청약 취소
		cancel() {
			if(!confirm('상품 신청을 취소하시겠습니까?')) {
				return false
			}
			this.$router.push('/')
		},
		// 팝업 열기
		async openPopup(type) {
			switch (type) {
				// 고객정보 조회
				case 'userInfo' :
					await this.getAuthContactInfo()
					this.popUserInfo = true
					break
				// 설치주소 조회
				case 'locationInfo' :
					this.popLocationInfo = true
					setTimeout(() => {
						this.$refs.dong.focus()
					}, 1)
					break
				// 주소검색 조회
				case 'addrInfo' :
					if (this.dong === '') {
						alert('검색을 원하시는 동 이름을 입력해주세요.')
						this.$refs.dong.focus()
						return false
					}
					await this.getCustAddressList()
					this.popAddrInfo = true
					setTimeout(() => {
						this.$refs.dong2.focus()
					}, 1)
					break
				// 건물검색 조회
				case 'buildingInfo' :
					if (this.dong === '') {
						alert('주소 검색 후에 가능합니다.')
						return false
					}
					if (this.bldgname === '') {
						alert('검색을 원하시는 건물 이름을 입력해주세요.')
						return false
					}
					this.detailAddr = ''
					this.popBuildingInfo = true
					await this.getCustAddressBldgList()
					break
				// 납부자 정보 조회
				case 'accountInfo' :
					if(!this.userInfoSel) {
						alert('기본정보를 먼저 조회해주세요.')
						return false
					}
					await this.getAccountListInfo()
					this.popAccountInfo = true
					break
			}
		},
		// 팝업 닫기
		closePopup(type) {
			switch (type) {
				// 고객정보 조회
				case 'userInfo' :
					this.userInfoSel = this.userInfoTemp
					this.cdmCustNoType = this.userInfoSel.custTypeCd
					if(this.cdmCustNoType === '3'){
						this.custNoType = '3'
						this.custNoTypeNm = '법인번호'
					}else if(this.cdmCustNoType === '4' || this.cdmCustNoType === '10'){
						this.custNoType = '4'
						this.custNoTypeNm = '관청'
					}else if(this.cdmCustNoType === '2' || this.cdmCustNoType === '8'){
						this.custNoType = '8'
						this.custNoTypeNm = '사업자번호'
					}else if(this.cdmCustNoType === '9'){
						this.custNoType = '9'
						this.custNoTypeNm = '기타'
					}else if(this.cdmCustNoType === '5'){
						this.custNoType = 'B'
						this.custNoTypeNm = '임의단체'
					}else if(this.cdmCustNoType === '6'){
						this.custNoType = 'C'
						this.custNoTypeNm = '해외사업자번호'
					}
					this.popUserInfo = false
					break
				// 설치주소 조회
				case 'locationInfo' :
					// this.addrInfo_sel = this.addrInfo
					// this.buildingInfo_sel = this.buildingInfo
					this.fullAddr = this.buildingInfo.addr1 + this.buildingInfo.addr2 + this.detailAddr
					this.popLocationInfo = false
					break
				// 주소검색 조회
				case 'addrInfo' :
					if (this.addrInfo === '') {
						alert('검색 결과를 선택해주세요.')
						return false
					}
					// this.addrInfo_sel = this.addrInfo
					this.popAddrInfo = false
					setTimeout(() => {
						this.$refs.building.focus()
					}, 1)
					break
				// 건물검색 조회
				case 'buildingInfo' :
					if (this.buildingInfo === '') {
						alert('검색 결과를 선택해주세요.')
						return false
					}
					if(this.detailAddr == '') {
						alert('상세주소를 입력해주세요.')
						return false
					}
					// this.buildingInfo_sel = this.buildingInfo
					this.popBuildingInfo = false
					break
				// 납부자 정보 조회
				case 'accountInfo' :
					this.accountInfoSel = this.accountInfoTemp
					this.payMethCd = this.accountInfoSel.payMethCd
					this.acrndDspTypeCd = this.accountInfoSel.acrndDspTypeCd
					this.popAccountInfo = false
					break
			}
		},
		// 첨부서류 업로드
		uploadFile(e) {
			e.preventDefault()
			var datakey = e.currentTarget.getAttribute('data-key')
			var file = e.currentTarget.files[0]
			var fileSize = e.currentTarget.files[0].size
			if(fileSize > 31457280) {
				alert('파일 용량은 30MB 이하여야 합니다.')
				return false
			}
			if(!file.type.includes('image')){
				alert('이미지 파일만 가능합니다.')
				return false
			}
			switch (datakey) {
				case 'imgBiz':
					this.imageFile.imgBiz = file
					break;
				case 'imgCeo':
					this.imageFile.imgCeo = file
					break;
				case 'imgAge':
					this.imageFile.imgAge = file
					break;
				case 'imgWar':
					this.imageFile.imgWar = file
					break;
				case 'imgSeal':
					this.imageFile.imgSeal = file
					break;
			}
			document.getElementById(`${datakey}Txt`).value = e.currentTarget.files[0].name
		},
		// PDF OPEN
		openPdf(url) {
			window.open(url, "_blank");
		},
		// 위임장 샘플 다운로드
		download() {
			let element = document.createElement('a')
			element.setAttribute('href' ,'https://flexon.kt.co.kr/gigaofficefile/kt_gigaoffice_agr_05.pdf')
			element.setAttribute('target' ,'_blank')
			element.click()
		}
	}
}
</script>
<style>
	.a-left { text-align: left; }
	.show { display:block !important; }
	.hide { display:none; }
</style>